import React from 'react';
import {Container} from 'react-bootstrap'
import MediosPagoEfectivo from '../../assets/mediosPagoEf.PNG'
import './styles.css';
function FormaPago() {
  window.scrollTo(0, 0)

  return (
      <Container fluid className='bodyContainer'>
        <h1 className='titulo'>Formas de Pago</h1>
        <p className='mb-4 mt-5'>En Dos Producciones Turismo reunimos los mejores medios de pago para que tengas a mano muchas alternativas para pagar tus alojamientos.</p>
        <h5>Pago en efectivo, transferencia bancaria, depósito bancario. Se realiza la reserva con el 50% de anticipo y el otro 50% en el complejo.</h5>
        <h5 className='mt-5'>Pago en efectivo en puntos de pago.</h5>
        <img className='fullwidth mb-5' src={MediosPagoEfectivo} alt='medios de Pago en Efectivo'/>
      </Container>
  );
}

export default FormaPago;
