import React from "react";
import "../styles.css";
import {
  Container,
  Carousel,
  Col,
  Row,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import cab1 from "../../../assets/complejosol/cab1.png";
import cab2 from "../../../assets/complejosol/cab2.png";
import cab3 from "../../../assets/complejosol/cab3.png";
import cab4 from "../../../assets/complejosol/cab4.png";
import cab5 from "../../../assets/complejosol/cab5.png";
import cab6 from "../../../assets/complejosol/cab6.jpg";
import cab7 from "../../../assets/complejosol/cab7.png";
import cab8 from "../../../assets/complejosol/cab8.jpeg";
import dpto1 from "../../../assets/complejosol/dpto1.png";
import dpto2 from "../../../assets/complejosol/dpto2.png";
import dpto3 from "../../../assets/complejosol/dpto3.png";
import dpto4 from "../../../assets/complejosol/dpto4.png";
import dpto5 from "../../../assets/complejosol/dpto5.png";
import dpto6 from "../../../assets/complejosol/dpto6.jpg";
import suite1 from "../../../assets/complejosol/suite1.jpeg";
import suite2 from "../../../assets/complejosol/suite2.jpeg";
import suite3 from "../../../assets/complejosol/suite3.jpeg";
import suite4 from "../../../assets/complejosol/suite4.jpeg";
import suite5 from "../../../assets/complejosol/suite6.jpg";
import slider1 from "../../../assets/complejosol/slider1.JPG";
import slider2 from "../../../assets/complejosol/slider2.JPG";
import slider3 from "../../../assets/complejosol/slider3.JPG";
import slider4 from "../../../assets/complejosol/slider4.JPG";
import slider5 from "../../../assets/complejosol/slider5.JPG";
import slider6 from "../../../assets/complejosol/slider6.JPG";
import departamento from "../../../assets/complejosol/departamento.mp4";
import suite from "../../../assets/complejosol/suites.mp4";
import cabana from "../../../assets/complejosol/cabañas.mp4";
function Sol() {
  window.scrollTo(0, 0);

  return (
    <Container className="bodyContainer" fluid>
      <h1 className="titulo">Complejo del Sol</h1>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider2}
            alt="#FDDDosProducciones"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider3}
            alt="Complejo del sol #music"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider4}
            alt="Complejo del sol #micro"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider5}
            alt="Complejo del sol #micro"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider6}
            alt="Complejo del sol #micro"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={slider1} alt="Complejo del sol" />
        </Carousel.Item>
      </Carousel>
      <hr />
      <section className="section-conocer">
        <h2>Conocé un poco más</h2>
        <p>
          El Complejo del Sol está ubicado a 30 minutos del predio de la Fiesta
          de Disfraces, frente al río, en la Ciudad de Diamante, Entre Ríos. El
          huésped podrá disfrutar de los servicios e instalaciones libremente de
          todo el complejo, que este año contará con más de 250 participantes a
          la Fiesta de Disfraces edición 2023.
        </p>
        <p>
          El Check-in se realiza a partir del Viernes 6 y el Check-out es el
          Domingo 8 a las 18 horas, luego de haber descansado y disfrutado de la
          Fiesta de Disfraces.{" "}
        </p>
        <hr />
        <h2>Servicios</h2>
        <Row>
          <p className="m-3">
            <i className="fa fa-wifi"></i> WIFI Gratis
          </p>
          <p className="m-3">
            <i className="fa fa-car"></i> Estacionamiento privado techado
          </p>
          <p className="m-3">
            <i className="fa fa-bath"></i> Ropa Blanca
          </p>
          <p className="m-3">
            <i className="fa fa-fire"></i> Cocina
          </p>
          <p className="m-3">
            <i className="fa fa-user-shield"></i> Seguridad las 24 horas
          </p>
          <p className="m-3">
            <i className="fa fa-fire"></i> Parrillas
          </p>
          <p className="m-3">
            <i className="fa fa-users"></i> 2 SUMs
          </p>
          <p className="m-3">
            <i className="fa fa-swimmer"></i> 6 Piletas
          </p>
          <p className="m-3">
            <i className="fa fa-umbrella-beach"></i> Solárium
          </p>
        </Row>
        <hr />
        <h2>El Paquete Incluye:</h2>
        <ul>
          <li>
            <p>Alojamiento de 3 días y 2 noches en el complejo del Sol</p>
          </li>
          <li>
            <p>
              Traslado ida y vuelta desde el complejo del Sol a la Fiesta de
              Disfraces realizado por la empresa de transporte Ciudad de Crespo
            </p>
          </li>
          <li>
            <p>Coordinación permanente</p>
          </li>
          <li>
            <p>Pool Party</p>
          </li>
          <li>
            <p>Fiesta Temática Nocturna</p>
          </li>
          <li>
            <p>
              La entrada a la Fiesta de Disfraces es optativa (NO ESTA INCLUIDA)
            </p>
          </li>
        </ul>
        <hr />
        <h2>Itinerario</h2>
        <p>
        <span>Viernes 6</span> – <i className="fa fa-sign-in-alt"></i>{" "}
          Check-in / Fiesta de Bienvenida y Temática <br />
          <span>Sábado 7</span> – Pool Party / Fiesta de Disfraces <br />
          <span>Domingo 20</span> - <i className="fa fa-sign-out-alt"></i>{" "}
          Check-out por la tarde
        </p>
      </section>
      <hr />
      <h1 className="titulo">Paquetes</h1>
      <Row className="alojamientos">
        <Col md="4" sm="12" className="col-alojamientos">
          <Carousel interval={null}>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={dpto1}
                alt="Imagen departamento 1"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={dpto2}
                alt="Imagen departamento 2"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={dpto3}
                alt="Imagen departamento 3"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={dpto4}
                alt="Imagen departamento 4"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={dpto5}
                alt="Imagen departamento 5"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={dpto6}
                alt="Imagen departamento 6"
              />
            </Carousel.Item>
            <Carousel.Item>
              <video src={departamento} controls style={{ width: "100%" }} />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md="4" sm="12" className="detalle-alojamientos">
          <h4>Departamento para 4 personas</h4>
          {/* ICONS AMMENITIES */}
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Aire Acondicionado</Popover>}
          >
            <i className="fa fa-snowflake icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>WIFI Gratis</Popover>}
          >
            <i className="fa fa-wifi icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Piscina</Popover>}
          >
            <i className="fa fa-swimmer icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Estacionamiento Gratis</Popover>}
          >
            <i className="fa fa-car icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Seguridad 24hs</Popover>}
          >
            <i className="fa fa-user-shield icons"></i>
          </OverlayTrigger>
          <Row className="p-2">
            <Col className="col-dias">
              <p className="m-0">Desde:</p>
              <p>
                <span className="date">Vie 6 Oct</span>
              </p>
            </Col>
            <Col className="col-dias border-left border-light">
              <p className="m-0">Hasta:</p>
              <p>
                <span className="date">Dom 8 Oct</span>
              </p>
            </Col>
          </Row>
          <Col className="incluye">
            Incluye traslado ida y vuelta a la Fiesta
          </Col>
        </Col>
        <Col md="4" sm="12" className="precio-alojamientos">
          <p className="pack">3 DÍAS / 2 NOCHES</p>
          <p><span>Precio por persona</span> <h4 className="price">$55.000</h4></p>
          <p className="small">precio total para 4 personas <br/><span>$220.000</span></p>
          <Link className="small" to={"/formas-pago"}>
            <i className="fa fa-credit-card icons"></i> Formas de Pago
          </Link>
          <Link
            className="button-alojamientos"
            to={{
              pathname: "/reserva",
              state: {
                alojamiento: "Complejo del Sol",
                tipoAlojamiento: "Departamento para 4 personas",
              },
            }}
          >
            Reservar
          </Link>
        </Col>
      </Row>
      <Row className="alojamientos">
        <Col md="4" sm="12" className="col-alojamientos">
          <Carousel interval={null}>
            <Carousel.Item>
              <img
                className="imagen-alojamientos inverted"
                src={dpto4}
                alt="Imagen departamento 4"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos inverted"
                src={dpto1}
                alt="Imagen departamento 1"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos inverted"
                src={dpto2}
                alt="Imagen departamento 2"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos inverted"
                src={dpto6}
                alt="Imagen departamento 6"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos inverted"
                src={dpto3}
                alt="Imagen departamento 3"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="imagen-alojamientos inverted"
                src={dpto5}
                alt="Imagen departamento 5"
              />
            </Carousel.Item>
            <Carousel.Item>
              <video src={departamento} controls style={{ width: "100%" }} />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md="4" sm="12" className="detalle-alojamientos">
          <h4>Departamento para 5 personas</h4>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Aire Acondicionado</Popover>}
          >
            <i className="fa fa-snowflake icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>WIFI Gratis</Popover>}
          >
            <i className="fa fa-wifi icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Piscina</Popover>}
          >
            <i className="fa fa-swimmer icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Estacionamiento Gratis</Popover>}
          >
            <i className="fa fa-car icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Seguridad 24hs</Popover>}
          >
            <i className="fa fa-user-shield icons"></i>
          </OverlayTrigger>{" "}
          {/* Disponibilidad */}
          <Row className="p-2">
            <Col className="col-dias">
              <p className="m-0">Desde:</p>
              <p>
                <span className="date">Vie 6 Oct</span>
              </p>
            </Col>
            <Col className="col-dias border-left border-light">
              <p className="m-0">Hasta:</p>
              <p>
                <span className="date">Dom 8 Oct</span>
              </p>
            </Col>
          </Row>
          <Col className="incluye">
            Incluye traslado ida y vuelta a la Fiesta
          </Col>
        </Col>
        <Col md="4" sm="12" className="precio-alojamientos">
          <p className="pack">3 DÍAS / 2 NOCHES</p>
          <p><span>Precio por persona</span> <h4 className="price">$55.000</h4></p>
          <p className="small">precio total para 5 personas <br/><span>$275.000</span></p>
          <Link className="small" to={"/formas-pago"}>
            <i className="fa fa-credit-card icons"></i> Formas de Pago
          </Link>
          <Link
            className="button-alojamientos"
            to={{
              pathname: "/reserva",
              state: {
                alojamiento: "Complejo del Sol",
                tipoAlojamiento: "Departamento para 5 personas",
              },
            }}
          >
            Reservar
          </Link>
        </Col>
      </Row>
      <Row className="alojamientos">
        <Col md="4" sm="12" className="col-alojamientos">
          <Carousel interval={null}>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={suite1}
                alt="Imagen suite 1"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={suite2}
                alt="Imagen suite 2"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={suite4}
                alt="Imagen suite 4"
              />
            </Carousel.Item>
            <Carousel.Item>
              <video src={suite} controls style={{ width: "100%" }} />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md="4" sm="12" className="detalle-alojamientos">
          <h4>Suite para 4 personas</h4>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Aire Acondicionado</Popover>}
          >
            <i className="fa fa-snowflake icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>WIFI Gratis</Popover>}
          >
            <i className="fa fa-wifi icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Piscina</Popover>}
          >
            <i className="fa fa-swimmer icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Estacionamiento Gratis</Popover>}
          >
            <i className="fa fa-car icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Seguridad 24hs</Popover>}
          >
            <i className="fa fa-user-shield icons"></i>
          </OverlayTrigger>{" "}
          {/* Disponibilidad */}
          <Row className="p-2">
            <Col className="col-dias">
              <p className="m-0">Desde:</p>
              <p>
                <span className="date">Vie 6 Oct</span>
              </p>
            </Col>
            <Col className="col-dias border-left border-light">
              <p className="m-0">Hasta:</p>
              <p>
                <span className="date">Dom 8 Oct</span>
              </p>
            </Col>
          </Row>
          <Col className="incluye">
            Incluye traslado ida y vuelta a la Fiesta
          </Col>
        </Col>
        <Col md="4" sm="12" className="precio-alojamientos">
          <p className="pack">3 DÍAS / 2 NOCHES</p>
          <p><span>Precio por persona</span> <h4 className="price">$55.000</h4></p>
          <p className="small">precio total para 4 personas <br/><span>$220.000</span></p>
          <Link className="small" to={"/formas-pago"}>
            <i className="fa fa-credit-card icons"></i> Formas de Pago
          </Link>
          <Link
            className="button-alojamientos"
            to={{
              pathname: "/reserva",
              state: {
                alojamiento: "Complejo del Sol",
                tipoAlojamiento: "Suite para 4 personas",
              },
            }}
          >
            Reservar
          </Link>
        </Col>
      </Row>
      <Row className="alojamientos">
        <Col md="4" sm="12" className="col-alojamientos">
          <Carousel interval={null}>
            <Carousel.Item>
              <img
                className="imagen-alojamientos inverted"
                src={suite3}
                alt="Imagen suite 3"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos inverted"
                src={suite4}
                alt="Imagen suite 4"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos inverted"
                src={suite1}
                alt="Imagen suite 1"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos inverted"
                src={suite5}
                alt="Imagen suite 6"
              />
            </Carousel.Item>
            <Carousel.Item>
              <video src={suite} controls style={{ width: "100%" }} />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md="4" sm="12" className="detalle-alojamientos">
          <h4>Suite para 5 personas</h4>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Aire Acondicionado</Popover>}
          >
            <i className="fa fa-snowflake icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>WIFI Gratis</Popover>}
          >
            <i className="fa fa-wifi icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Piscina</Popover>}
          >
            <i className="fa fa-swimmer icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Estacionamiento Gratis</Popover>}
          >
            <i className="fa fa-car icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Seguridad 24hs</Popover>}
          >
            <i className="fa fa-user-shield icons"></i>
          </OverlayTrigger>{" "}
          {/* Disponibilidad */}
          <Row className="p-2">
            <Col className="col-dias">
              <p className="m-0">Desde:</p>
              <p>
                <span className="date">Vie 6 Oct</span>
              </p>
            </Col>
            <Col className="col-dias border-left border-light">
              <p className="m-0">Hasta:</p>
              <p>
                <span className="date">Dom 8 Oct</span>
              </p>
            </Col>
          </Row>
          <Col className="incluye">
            Incluye traslado ida y vuelta a la Fiesta
          </Col>
        </Col>
        <Col md="4" sm="12" className="precio-alojamientos">
          <p className="pack">3 DÍAS / 2 NOCHES</p>
          <p><span>Precio por persona</span> <h4 className="price">$55.000</h4></p>
          <p className="small">precio total para 5 personas <br/><span>$275.000</span></p>
          <Link className="small" to={"/formas-pago"}>
            <i className="fa fa-credit-card icons"></i> Formas de Pago
          </Link>
          <Link
            className="button-alojamientos"
            to={{
              pathname: "/reserva",
              state: {
                alojamiento: "Complejo del Sol",
                tipoAlojamiento: "Suite para 5 personas",
              },
            }}
          >
            Reservar
          </Link>
        </Col>
      </Row>
      <Row className="alojamientos">
        <Col md="4" sm="12" className="col-alojamientos">
          <Carousel interval={null}>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab8}
                alt="Imagen cabaña 8"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab1}
                alt="Imagen cabaña 1"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab2}
                alt="Imagen cabaña 2"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab3}
                alt="Imagen cabaña 3"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab4}
                alt="Imagen cabaña 4"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab5}
                alt="Imagen cabaña 5"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab6}
                alt="Imagen cabaña 6"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab7}
                alt="Imagen cabaña 7"
              />
            </Carousel.Item>
            <Carousel.Item>
              <video src={cabana} controls style={{ width: "100%" }} />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md="4" sm="12" className="detalle-alojamientos">
          <h4>Cabañas para 8 personas</h4>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Aire Acondicionado</Popover>}
          >
            <i className="fa fa-snowflake icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>WIFI Gratis</Popover>}
          >
            <i className="fa fa-wifi icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Piscina</Popover>}
          >
            <i className="fa fa-swimmer icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Estacionamiento Gratis</Popover>}
          >
            <i className="fa fa-car icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Seguridad 24hs</Popover>}
          >
            <i className="fa fa-user-shield icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Parrilla</Popover>}
          >
            <i className="fa fa-fire icons"></i>
          </OverlayTrigger>
          <Row className="p-2">
            <Col className="col-dias">
              <p className="m-0">Desde:</p>
              <p>
                <span className="date">Vie 6 Oct</span>
              </p>
            </Col>
            <Col className="col-dias border-left border-light">
              <p className="m-0">Hasta:</p>
              <p>
                <span className="date">Dom 8 Oct</span>
              </p>
            </Col>
          </Row>
          <Col className="incluye">
            Incluye traslado ida y vuelta a la Fiesta
          </Col>
        </Col>
        <Col md="4" sm="12" className="precio-alojamientos">
          <p className="pack">3 DÍAS / 2 NOCHES</p>
          <p><span>Precio por persona</span> <h4 className="price">$55.000</h4></p>
          <p className="small">precio total para 8 personas <br/><span>$440.000</span></p>
          <Link className="small" to={"/formas-pago"}>
            <i className="fa fa-credit-card icons"></i> Formas de Pago
          </Link>
          <Link
            className="button-alojamientos"
            to={{
              pathname: "/reserva",
              state: {
                alojamiento: "Complejo del Sol",
                tipoAlojamiento: "Cabañas para 8 personas",
              },
            }}
          >
            Reservar
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default Sol;
