import React, { useEffect, useState } from 'react';
import './styles.css';
import {Container, Col, Button, Form, Modal} from 'react-bootstrap'
import {AvForm, AvField, AvInput} from 'availity-reactstrap-validation'
import emailjs from '@emailjs/browser';


function Contacto () {  
  const [mailSent, setMailSent] = useState(false)
  const [errors, setErrors] = useState(null)
  useEffect(() => window.scrollTo(0, 0), [])

  const sendEmail = (value) => {
    const form = document.getElementById("contact-form")
    emailjs.sendForm('service_zeaqhie', 'template_bmkuwvz', form, 'eBd-Y9GSlmSHdj4UJ')
      .then((result) => {
          setMailSent(!!result.text);
          form.reset();
      }, (err) => {
          setErrors(err.text);
      });
  };

  const handleSubmit = (event, errors, values) => {
    if (!errors || errors.length < 1) {
        sendEmail(values)
      }
  }
  const handleClose = () => {
    setMailSent(!mailSent)
  }
  return (
    <Container fluid className='bodyContainer'>
      <h1 className='titulo'>Contacto</h1>
      <p className="m-3">
        Realizanos cualquier consulta por este medio o cualquiera de nuestras redes, ¡te responderemos a la brevedad para poder responder todas tus dudas!. 
      </p>
      <Modal show={mailSent} onHide={handleClose}>
        <Modal.Body className='respuesta'><h3 ><strong>¡Gracias por contactarse con nosotros!</strong></h3> <br/> 
        <h4 className='mb-4'>Le responderemos sus dudas a la brevedad.</h4>
        <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Body>
      </Modal>
      <Container>
        <AvForm
          id="contact-form"
          onSubmit={handleSubmit}
        >
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <AvField
                required
                type="text"
                placeholder="Nombre"
                errorMessage="Es necesario que ingreses un nombre"
                name="nombre"
                />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="apellido">
              <Form.Label>Apellido</Form.Label>
              <AvField
                required
                type="text"
                placeholder="Apellido"
                errorMessage="Es necesario que ingreses un apellido"
                name="apellido"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="celular">
              <Form.Label>Celular</Form.Label>
                <AvField
                  type="number"
                  placeholder="Celular"
                  name="celular"
                  errorMessage="Es necesario que ingreses un celular"
                  required
                />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="email">
              <Form.Label>E-mail</Form.Label>
                <AvField
                  type="email"
                  placeholder="Email"
                  name="email"
                  errorMessage="Es necesario que ingreses un email"
                  required
                />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="mensaje">
              <Form.Label>Mensaje</Form.Label>
              <AvInput 
                rows={5}
                type="textarea"
                name='mensaje'
                className="form-control"
                errorMessage="Es necesario que ingreses un mensaje"
                placeholder="Escriba aquí su mensaje"
                required
              />
            </Form.Group>
          </Form.Row>
          {errors && <p>{errors}</p>}
          <Button type="submit" className='send-form'>Enviar</Button>
        </AvForm>
      </Container>
    </Container>
    
  );
}

export default Contacto;
