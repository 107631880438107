import React from "react";
import "./styles.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Aristides from "../../assets/aristides.jpeg";
import aristideslogo from "../../assets/don-aristides.png";
function Complejos() {
  window.scrollTo(0, 0);
  return (
    <Container fluid className="bodyContainer">
      <h1 className="titulo">Complejos de Cabañas</h1>
      <h5 className="m-3">
      ¡No pierdas la oportunidad de reservar nuestros exclusivos complejos para una escapada inolvidable! <br/> Del Viernes 6 al Domingo 8 de Octubre del 2023, te invitamos a disfrutar de días llenos de confort y diversión en nuestros increíbles complejos.!
      </h5>
      <Row className="img-fluid justify-content-center">
        <Col md={4} sm={12}>
          <Card>
            <Link to="/complejos/sol" >
              <Card.Img variant="top" src="https://complejodelsol.com.ar/site/wp-content/uploads/2022/11/image00004.jpg" />
              <Card.Body>
                <Row className="p-2">
                  <img className="img-fluid" src="https://complejodelsol.com.ar/site/wp-content/uploads/2022/11/logo_complejo_del_sol2.jpg" alt="Complejo del Sol" />
                </Row>
              </Card.Body>
            </Link>
          </Card>
          <i
            className="fa fa-map-marker-alt icons"
            style={{ color: "red" }}
          ></i>
          <a
            href="https://www.google.com.ar/maps/place/Complejo+Del+Sol/@-32.0606732,-60.647308,17z/data=!3m1!4b1!4m5!3m4!1s0x95b5cdb3ee14ae33:0x2abca6c73f6cc923!8m2!3d-32.0606778!4d-60.645114"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ver ubicación
          </a>
        </Col>
        <Col md={4} sm={12}>
          <Card>
          <div id="background">
              <p id="bg-text">Proximamente</p>
            </div>
            {/* <Link to="/complejos/aristides" className="button-card"> */}
              <Card.Img variant="top" src={Aristides} />
              <Card.Body>
                <img className="img-fluid justify-content-center" src={aristideslogo} alt="Don Arístides" />
              </Card.Body>
            {/* </Link> */}
          </Card>
          <i
            className="fa fa-map-marker-alt icons"
            style={{ color: "red" }}
          ></i>
          <a
            href="https://www.google.com.ar/maps/place/Caba%C3%B1as+Don+Aristides/@-32.0606732,-60.647308,17z/data=!4m5!3m4!1s0x0:0x552b91b0af58154b!8m2!3d-32.0602561!4d-60.6456088"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ver ubicación
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Complejos;
