import React from "react";
import "./styles.css";
import hotel from "../../assets/hotel.png";
import videoA from "../../assets/videopromo.mp4";
import { Container, Row, Col, Card } from "react-bootstrap";
import {Link} from 'react-router-dom'
function Home() {
  window.scrollTo(0, 0);
  return (
    <Container fluid className="bodyContainer">
      <h1 className="titulo">Fiesta de Disfraces - Edición 2023 -</h1>
      <video muted src={videoA} className="video" autoPlay loop></video>
      <p>
        La <strong>edición FDD 2023</strong> se realizará el{" "}
        <strong>Sábado 7 de Octubre</strong>. En el predio
        de 14 hectáreas ubicado sobre Acceso Norte de la ciudad de Paraná.
      </p>
      <h5>
        Te ofrecemos los mejores alojamientos exclusivos y traslados para que
        disfrutes del mejor fin de semana del año.
      </h5>
      <h2 className="subtitulo">Alojamientos</h2>
      <Row className="mb-5 justify-content-center">
        <Col md={5} sm={12}>
          <Card className="card-alojamientos">
            <Link to="/complejos">
              <Card.Img variant="top" src={"https://complejodelsol.com.ar/site/wp-content/uploads/2022/11/image00004.jpg"} />
              <Card.Body>
              <p className="pack-right">3 DÍAS / 2 NOCHES</p>
                <Card.Title>
                  Complejos de Cabañas
                </Card.Title>
                <Card.Text>
                  Complejos turísticos ubicados en una barranca con una vista espectacular, piletas, parrillas, solarium, fiestas y mucho más por parte de Dos Producciones Turismo.
                </Card.Text>
              </Card.Body>
            </Link>
          </Card>
        </Col>
        <Col md={5} sm={12}>
          <Card className="card-alojamientos">
            <div id="background">
              <p id="bg-text">Proximamente</p>
            </div>
            <Link  to="/complejos">  
            <Card.Img variant="top" src={hotel} />
              <Card.Body>
                <Card.Title>Hoteles</Card.Title>
                <Card.Text>
                  Hoteles ubicados en la ciudad de Paraná para facil acceso a la Fiesta.
                </Card.Text>
              </Card.Body>
            </Link>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
