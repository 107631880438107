import React from "react";
import logo from "../../assets/dpturismo.png";
import "./App.css";
import { Navbar, Container, Nav } from "react-bootstrap";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "../Home";
import Complejos from "../Complejos";
import Sol from "../Complejos/components/sol";
import Aristides from "../Complejos/components/aristides";
import PreguntasFreq from "../PreguntasFrecuentes";
import FormaPago from "../FormPago";
import Reserva from "../Reserva";
import Contacto from "../Contacto";

function App() {
  return (
    <Router>
      <div className="App">
        {/* PORTADA ACA */}
        {/* <div className="portada">
          <img src={portada} alt="Portada Dos Producciones Turismo" />
        </div> */}
        <Navbar collapseOnSelect expand="lg" className="navbar-fdd" >
          <Container fluid>
            <Navbar.Brand href="/">
              <img
                src={logo}
                className="App-logo"
                alt="Dos Producciones Turismo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link
                  href="/"
                  className={window.location.pathname === "/" ? "active" : ""}
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  href="/preguntas-frecuentes"
                  className={
                    window.location.pathname === "/preguntas-frecuentes"
                      ? "active"
                      : ""
                  }
                >
                  Preguntas Frecuentes
                </Nav.Link>
                <Nav.Link
                  href="/formas-pago"
                  className={
                    window.location.pathname === "/formas-pago" ? "active" : ""
                  }
                >
                  Formas de Pago
                </Nav.Link>
                <Nav.Link
                  href="/reserva"
                  className={
                    window.location.pathname === "/reserva" ? "active" : ""
                  }
                >
                  Reserva
                </Nav.Link>
                <Nav.Link
                  href="/contacto"
                  className={
                    window.location.pathname === "/contacto" ? "active" : ""
                  }
                >
                  Contacto
                </Nav.Link>
              </Nav>
              <Nav>
                <div className="contact-social">
                  <a
                    href="http://m.me/DosProduccionesTurismo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-messenger"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/dosproduccionesturismo/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://api.whatsapp.com/send?phone=541131495030"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-whatsapp"></i>
                  </a>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Route exact path="/" component={Home} />
        <Route exact path="/complejos" component={Complejos} />
        <Route exact path="/complejos/sol" component={Sol} />
        <Route exact path="/complejos/aristides" component={Aristides} />
        <Route exact path="/preguntas-frecuentes" component={PreguntasFreq} />
        <Route exact path="/formas-pago" component={FormaPago} />
        <Route exact path="/reserva" component={Reserva} />
        <Route exact path="/contacto" component={Contacto} />
       
        <footer>
        <a
            href="https://api.whatsapp.com/send?phone=541131495030"
            target="_blank"
            rel="noopener noreferrer"
            className="fixed-button"
          >
              <img alt="WhatsApp" style={{marginRight:10, height:24}} src="https://res.cloudinary.com/hbrrdozyj/image/upload/q_auto,f_auto/v1595518710/whatsapp-logo_z5tahe.svg" /> {" "}
              Chatea con nosotros
          </a>
          <p>
            Copyright © 2022 | Todos los derechos reservados.{" "}
            <a
              href="http://www.dosproduccionesturismo.com.ar"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dos Producciones Turismo
            </a>
          </p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
