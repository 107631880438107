import React, { useEffect, useState } from "react";
import "./styles.css";
import { Container, Col, Button, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import emailjs from '@emailjs/browser';

function Reserva (props) {
  const [form, setForm] = useState({
    alojamiento: "",
    personas: []
  })
  const [mailSent, setMailSent] = useState(false)
  const [errors, setErrors] = useState(null)
  
  useEffect(() => {
    setForm({ ...form, ...props.location.state })
  }, [])

  const onChange = (field, e) => {
    let formed = form;
    formed[field] = e;
    setForm(formed);
  }

  const sendEmail = () => {
    const form = document.getElementById("reserve-form")
    emailjs.sendForm('service_zeaqhie', 'template_s06dq7h', form, 'eBd-Y9GSlmSHdj4UJ')
      .then((result) => {
          setMailSent(!!result.text);
          form.reset();

      }, (err) => {
          setErrors(err.text);
      });
  };
  const handleSubmit = (event, errors, values) => {
    if (!errors || errors.length < 1) {
      sendEmail()
    }
  };

  const handlePersonaChange = (field, evt, i) => {
    let formed = form;
    formed.personas[i][field] = evt;
    setForm(formed)
  };

  const handleAddPersona = () => {
    setForm({
      ...form,
      personas: form.personas.concat([
        { nombre: "", apellido: "", dni: "" }
      ])
    })
    
  };
  const handleClose = () => {
    setMailSent(!mailSent)
  }

  const handleRemovePersona = idx => () => {
    setForm({
      ...form,
      personas: form.personas.filter((s, sidx) => idx !== sidx)
    })
  };
    return (
      <Container fluid className="bodyContainer">
        <h1 className="titulo">Reserva tu Lugar</h1>
        <p className="m-3">
          Complete los siguientes datos y seleccione la cantidad de pasajeros
          para realizar la reserva de su alojamiento. <br />
          Una vez ingresada la reserva nos contactaremos con usted para
          coordinar la forma de pago.
        </p>
        <Modal show={mailSent} onHide={handleClose}>
          <Modal.Body className="respuesta">
            <h3>
              <strong>¡Su reserva está en proceso!</strong>
            </h3>{" "}
            <br />
            <h4 className="mb-4">
              A la brevedad un agente de Dos Producciones Turismo se contactará
              con usted.
            </h4>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Body>
        </Modal>
        <Container>
          <AvForm
            onSubmit={handleSubmit}
            model={form}
            id="reserve-form"
          >
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="nombre">
                <Form.Label>Nombre</Form.Label>
                <AvField
                  required
                  type="text"
                  placeholder="Nombre"
                  name="nombre"
                  errorMessage="Es necesario que ingreses un nombre"
                />
                <Form.Control.Feedback type="invalid">
                  Es necesario que completes un nombre
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="apellido">
                <Form.Label>Apellido</Form.Label>
                <AvField
                  required
                  type="text"
                  placeholder="Apellido"
                  name="apellido"
                  errorMessage="Es necesario que ingreses un apellido"
                />
                <Form.Control.Feedback type="invalid">
                  Es necesario que completes un apellido
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="dni">
                <Form.Label>DNI</Form.Label>
                <AvField
                  type="number"
                  placeholder="DNI"
                  min={1}
                  required
                  name="dni"
                  errorMessage="Es necesario que ingreses un dni"
                />
                <Form.Control.Feedback type="invalid">
                  Es necesario que completes un DNI
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="celular">
                <Form.Label>Celular</Form.Label>
                <AvField
                  type="number"
                  placeholder="Celular"
                  required
                  name="celular"
                  errorMessage="Es necesario que ingreses un celular"
                />
                <Form.Control.Feedback type="invalid">
                  Es necesario que completes un Celular
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="email">
                <Form.Label>E-mail</Form.Label>
                <AvField
                  type="email"
                  placeholder="Email"
                  required
                  name="email"
                  errorMessage="Es necesario que ingreses un email"
                />
                <Form.Control.Feedback type="invalid">
                  Es necesario que completes un E-mail
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="alojamiento">
                <Form.Label>Alojamiento</Form.Label>
                <AvField
                  type="select"
                  name="alojamiento"
                  onChange={e => onChange("alojamiento", e.target.value)}
                  required
                  errorMessage="Es necesario que ingreses un alojamiento"
                >
                  <option  key={1} value={""}>Seleccionar</option>
                  <option key={2} value={"Complejo Del Sol"}>Complejo del Sol</option>
                  {/* <option>Complejo Don Arístides</option> */}
                </AvField>
                <Form.Control.Feedback type="invalid">
                  Es necesario que completes un Alojamiento
                </Form.Control.Feedback>
                <p className="text-reserva">
                  * Su reserva esta sujeta a disponibilidad
                </p>
              </Form.Group>
              {/* form.alojamiento !== "" &&  */(
                  <Form.Group as={Col} md="6" controlId="alojamiento">
                    <Form.Label>Tipo de Alojamiento</Form.Label>
                    <AvField
                      type="select"
                      name="tipoAlojamiento"
                      required
                      errorMessage="Es necesario que ingreses un tipo de alojamiento"
                    >
                      <option key={0} value={""}>
                        Seleccionar
                      </option>
                      {/* form.alojamiento === "Complejo del Sol" &&  */[
                        <option key={1}>Cabañas para 8 personas</option>,
                        <option key={2}>Departamento para 4 personas</option>,
                        <option key={3}>Departamento para 5 personas</option>,
                        <option key={4}>Suite para 4 personas</option>,
                        <option key={5}>Suite para 5 personas</option>
                      ]}
                      {/* form.alojamiento ===
                        "Complejo Don Arístides" && [
                        <option key={1}>Cabañas para 8 personas</option>,
                        <option key={2}>Cabañas para 5 personas</option>,
                        <option key={3}>Cabañas para 4 personas</option>
                      ] */}
                    </AvField>
                    <Form.Control.Feedback type="invalid">
                      Es necesario que completes un Alojamiento
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
{/*               <Button
                type="button"
                onClick={handleAddPersona}
                className="button-add-persona"
              >
                Agregar Pasajero ({form.personas.length})
              </Button> */}
            </Form.Row>

         {/*    <Form.Row className="p-2">
              {form.personas.map((persona, idx) => (
                <Col md="4" key={idx}>
                  <Col md="12" className="card-persona p-4">
                    <h4 className="title-card-persona">
                      <i className="fa fa-user"></i> Pasajero {idx + 2}
                    </h4>
                    <hr />
                    <Button
                      className="button-card-persona"
                      type="button"
                      onClick={handleRemovePersona(idx)}
                    >
                      x
                    </Button>
                    <Form.Group controlId={`nombrePersona${idx + 1}`}>
                      <Form.Label>Nombre</Form.Label>
                      <AvField
                        required
                        type="text"
                        name={`personas[${idx}].nombre`}
                        placeholder="Nombre"
                        errorMessage="Es necesario que ingreses un nombre"
                      />
                      <Form.Control.Feedback type="invalid">
                        Es necesario que completes un nombre
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={`apellidoPersona${idx + 1}`}>
                      <Form.Label>Apellido</Form.Label>
                      <AvField
                        required
                        type="text"
                        name={`personas[${idx}].apellido`}
                        placeholder="Apellido"
                        errorMessage="Es necesario que ingreses un apellido"
                      />
                      <Form.Control.Feedback type="invalid">
                        Es necesario que completes un apellido
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={`dniPersona${idx + 1}`}>
                      <Form.Label>DNI</Form.Label>
                      <AvField
                        type="number"
                        placeholder="DNI"
                        name={`personas[${idx}].dni`}
                        min={1}
                        required
                        errorMessage="Es necesario que ingreses un dni"
                      />
                      <Form.Control.Feedback type="invalid">
                        Es necesario que completes un DNI
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Col>
              ))}
            </Form.Row> */}
            {errors && <p>{errors}</p>}
            <Button type="submit" className="send-form">
              Enviar
            </Button>
          </AvForm>
        </Container>
        <Link to={"/formas-pago"}>
          <i className="fab fa-cc-visa icons-tarjeta"></i> pagá hasta en 12
          cuotas <i className="fa fa-credit-card icons-tarjeta"></i>
        </Link>
        <p className="text-reserva">
          * Las reservas tienen un plazo de pago de 48hs a partir de su
          realización. Pasado ese plazo si no está abonada se eliminará y deberá
          ingresar una nueva reserva.
        </p>
      </Container>
    );
}

export default Reserva;
