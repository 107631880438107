import React from "react";
import "./styles.css";
import { Container, Accordion, Card } from "react-bootstrap";
function PreguntasFreq() {
  window.scrollTo(0, 0);
  return (
    <Container fluid className="bodyContainer">
      <h1 className="titulo">Preguntas Frecuentes</h1>
      <h4>Fiesta de Disfraces Paraná Octubre 2023</h4>
      <Accordion defaultActiveKey="0">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <strong>Cuándo y Dónde se hace?</strong>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Footer>
              La Fiesta de Disfraces 2023 será el Sábado 7 de Octubre, a partir de
              las 21:00 hs y finalizando a las 7:00hs.
              <br />
              El predio está ubicado sobre el Acceso Norte, entre las calles
              Juan Morath y Jorge Luis Borges en la ciudad de Paraná.
            </Card.Footer>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="10">
            <strong>¿Para qué fechas es la reserva?</strong>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="10">
            <Card.Footer>
              Los complejos de cabañas se venden del Viernes 6 al Domingo 8 de
              Octubre. La estadía es de 3 días y 2 noches.
              <br />
            </Card.Footer>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            <strong>¿Incluyen traslado a la fiesta?</strong>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Footer>
              <strong>SI</strong>, los complejos de cabañas incluyen traslados
              de ida y vuelta desde los complejos a la Fiesta y de la Fiesta a
              los complejos.
            </Card.Footer>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            <strong>
              ¿Los alojamientos están cerca del predio de la Fiesta de
              Disfraces?
            </strong>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Footer>
              Los complejos se encuentran a 30 minutos del predio de la Fiesta
              en Bus. <br />
            </Card.Footer>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="9">
            <strong>¿Los alojamientos cuentan con sábanas y toallas?</strong>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="9">
            <Card.Footer>
              <strong>SI</strong>, la mayoría dispone, pero siempre recomendamos
              que las lleven.
            </Card.Footer>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            <strong>¿Los alojamientos cuentan con estacionamiento?</strong>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Footer>
              <strong>SI</strong>, todos los alojamientos cuentan con
              estacionamiento (sujeto a disponibilidad de cocheras).
            </Card.Footer>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            <strong>¿Las cabañas tienen para cocinar?</strong>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Footer>
              No todos los alojamientos tienen cocina, te recomendamos que
              consultes a nuestros agentes de venta cual de ellos cuentan con
              esas prestaciones.
            </Card.Footer>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="5">
            <strong>¿Se puede cancelar la reserva?</strong>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Footer>
              La reserva se puede cancelar sin costo dentro de las 72hs de
              emitida la misma, luego se abonará un 30% de costo administrativo.
            </Card.Footer>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="6">
            <strong>¿A qué distancia estoy de la terminal de micros?</strong>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Card.Footer>
              Los complejos se encuentran a 15 cuadras de la terminal de micros
              de la localidad de Diamante. <br />
            </Card.Footer>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="7">
            <strong>¿La Fiesta de Disfraces se suspende por lluvia?</strong>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <Card.Footer>
              Las carpas de la Fiesta cuentan con piso y el predio está
              acondicionado con las medidas de seguridad necesarias para evitar
              la suspensión en caso de lluvia. Sin embargo, en caso de fuerza
              mayor, el evento será reprogramado y los tickets serán
              reutilizables para la nueva fecha.
            </Card.Footer>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="8">
            <strong>¿Incluye entrada a la FDD?</strong>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="8">
            <Card.Footer>
              La entrada a la Fiesta de Disfraces es opcional. CONSULTAR
            </Card.Footer>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Container>
  );
}

export default PreguntasFreq;
