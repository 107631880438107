import React from "react";
import "../styles.css";
import {
  Container,
  Carousel,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { Link } from "react-router-dom";
import slider1 from "../../../assets/aristides/slider1.jpg";
import slider2 from "../../../assets/aristides/slider2.jpg";
import slider3 from "../../../assets/aristides/slider3.jpg";
import slider4 from "../../../assets/aristides/slider4.jpg";
import cab5 from "../../../assets/aristides/cab5.jpg";
import cab51 from "../../../assets/aristides/cab51.jpg";
import cab4 from "../../../assets/aristides/cab4.jpg";
import cab41 from "../../../assets/aristides/cab41.jpg";
import cab8 from "../../../assets/aristides/cab8.jpg";
import cab81 from "../../../assets/aristides/cab81.jpg";
import cab82 from "../../../assets/aristides/cab82.jpg";

function Aristides() {
  window.scrollTo(0, 0);

  return (
    <Container className="bodyContainer" fluid>
      <h1 className="titulo">Complejo Don Arístides</h1>
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={slider1} alt="Don Aristides" />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider2}
            alt="Don Aristides Pileta"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider3}
            alt="Don Aristides Cabañas"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slider4}
            alt="Don Aristides Cabañas 2"
          />
        </Carousel.Item>
      </Carousel>
      <hr />
      <section className="section-conocer">
        <h2>Conocé un poco más</h2>
        <p>
          El Complejo Don Arístides está ubicado a 30 minutos del predio de la
          Fiesta de Disfraces. El mismo se encuentra frente al Complejo del Sol,
          ambos en la Ciudad de Diamante, Entre Rios por lo cual se podrá
          disfrutar de los servicios prestados en ambos complejos. Por ejemplo:
          piletas, solárium, gimnasio, sector gastronómico y SUM.
        </p>
        <p>
          El Check-in se realiza a partir del Viernes 6 y el Check-out es el
          Domingo 8 a las 18 horas, luego de haber descansado y disfrutado de la
          Fiesta de Disfraces.
        </p>
        <hr />
        <h2>Servicios</h2>
        <Row>
          <p className="m-3">
            <i className="fa fa-wifi"></i> WIFI Gratis
          </p>
          <p className="m-3">
            <i className="fa fa-car"></i> Estacionamiento privado bajo techo
          </p>
          <p className="m-3">
            <i className="fa fa-bath"></i> Ropa Blanca
          </p>
          <p className="m-3">
            <i className="fa fa-fire"></i> Cocina
          </p>
          <p className="m-3">
            <i className="fa fa-user-shield"></i> Seguridad las 24 horas
          </p>
          <p className="m-3">
            <i className="fa fa-fire"></i> Parrillas
          </p>
          <p className="m-3">
            <i className="fa fa-utensils"></i> Gastronomía
          </p>
          <p className="m-3">
            <i className="fa fa-users"></i> SUM
          </p>
          <p className="m-3">
            <i className="fa fa-swimmer"></i> Piletas
          </p>
          <p className="m-3">
            <i className="fa fa-umbrella-beach"></i> Solárium
          </p>
        </Row>
        <hr />
        <h2>El Paquete Incluye:</h2>
        <ul>
          <li>
            <p>Alojamiento de 3 días y 2 noches en el complejo Don Arístides</p>
          </li>
          <li>
            <p>
              Traslado ida y vuelta desde el complejo Don Arístides a la Fiesta
              de Disfraces realizado por la empresa de transporte Ciudad de
              Crespo
            </p>
          </li>
          <li>
            <p>Coordinación permanente</p>
          </li>
          <li>
            <p>Pool Party</p>
          </li>
          <li>
            <p>Fiesta Temática Nocturna</p>
          </li>
          <li>
            <p>
              La entrada a la Fiesta de Disfraces es optativa (No está Incluida)
            </p>
          </li>
        </ul>
        <hr />
        <h2>Itinerario</h2>
        <p>
          <span>Viernes 6</span> – <i className="fa fa-sign-in-alt"></i>{" "}
          Check-in / Fiesta de Bienvenida y Temática <br />
          <span>Sábado 7</span> – Pool Party / Fiesta de Disfraces <br />
          <span>Domingo 8</span> – <i className="fa fa-sign-out-alt"></i>{" "}
          Check-out por la tarde
        </p>
      </section>
      <hr />
      <h1 className="titulo">Paquetes</h1>
      <Row className="alojamientos">
        <Col md="4" sm="12" className="col-alojamientos">
          <Carousel interval={null}>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab4}
                alt="Cabaña para 4 personas"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab41}
                alt="Cabaña para 4 personas 1"
              />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md="4" sm="12" className="detalle-alojamientos">
          <h4>Cabañas para 4 personas</h4>
          {/* ICONS AMMENITIES */}
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>WIFI Gratis</Popover>}
          >
            <i className="fa fa-wifi icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Piscina</Popover>}
          >
            <i className="fa fa-swimmer icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Estacionamiento Gratis</Popover>}
          >
            <i className="fa fa-car icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Seguridad 24hs</Popover>}
          >
            <i className="fa fa-user-shield icons"></i>
          </OverlayTrigger>
          <Row>
            <Col className="col-dias">
              <p className="m-0">Desde:</p>
              <p>
                <span className="date">Vie 6 Oct</span>
              </p>
            </Col>
            <Col className="col-dias border-left border-light">
              <p className="m-0">Hasta:</p>
              <p>
                <span className="date">Dom 8 Oct</span>
              </p>
            </Col>
          </Row>
          <Col className="incluye">
            Incluye traslado ida y vuelta a la Fiesta
          </Col>
        </Col>
        <Col md="4" sm="12" className="precio-alojamientos">
          <p>Precio final por 3 días y 2 noches</p>
          <h4>$48.500 / Persona</h4>
          <p className="small">(valor total: $194.000)</p>
          <Link className="small" to={"/formas-pago"}>
            <i className="fa fa-credit-card icons"></i> pagá hasta en 12 cuotas
          </Link>
          <Link
            className="button-alojamientos"
            to={{
              pathname: "/reserva",
              state: {
                alojamiento: "Complejo Don Arístides",
                tipoAlojamiento: "Cabañas para 4 personas"
              }
            }}
          >
            Reservar
          </Link>
        </Col>
      </Row>
      <Row className="alojamientos">
        <Col md="4" sm="12" className="col-alojamientos">
          <Carousel interval={null}>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab5}
                alt="Cabaña para 5 personas"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab51}
                alt="Cabaña para 5 personas 1"
              />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md="4" sm="12" className="detalle-alojamientos">
          <h4>Cabañas para 5 personas</h4>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>WIFI Gratis</Popover>}
          >
            <i className="fa fa-wifi icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Piscina</Popover>}
          >
            <i className="fa fa-swimmer icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Estacionamiento Gratis</Popover>}
          >
            <i className="fa fa-car icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Seguridad 24hs</Popover>}
          >
            <i className="fa fa-user-shield icons"></i>
          </OverlayTrigger>{" "}
          {/* Disponibilidad */}
          <Row>
            <Col className="col-dias">
              <p className="m-0">Desde:</p>
              <p>
                <span className="date">Vie 6 Oct</span>
              </p>
            </Col>
            <Col className="col-dias border-left border-light">
              <p className="m-0">Hasta:</p>
              <p>
                <span className="date">Dom 8 Oct</span>
              </p>
            </Col>
          </Row>
          <Col className="incluye">
            Incluye traslado ida y vuelta a la Fiesta
          </Col>
        </Col>
        <Col md="4" sm="12" className="precio-alojamientos">
          <p>Precio final por 3 días y 2 noches</p>
          <h4>$48.500 / Persona</h4>
          <p className="small">(valor total: $242.500)</p>
          <Link className="small" to={"/formas-pago"}>
            <i className="fa fa-credit-card icons"></i> pagá hasta en 12 cuotas
          </Link>
          <Link
            className="button-alojamientos"
            to={{
              pathname: "/reserva",
              state: {
                alojamiento: "Complejo Don Arístides",
                tipoAlojamiento: "Cabañas para 5 personas"
              }
            }}
          >
            Reservar
          </Link>
        </Col>
      </Row>
      <Row className="alojamientos">
        <Col md="4" sm="12" className="col-alojamientos">
          <Carousel interval={null}>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab8}
                alt="Cabaña para 8 personas"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab81}
                alt="Cabaña para 8 personas 1"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="imagen-alojamientos"
                src={cab82}
                alt="Cabaña para 8 personas 2"
              />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md="4" sm="12" className="detalle-alojamientos">
          <h4>Cabañas para 8 personas</h4>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>WIFI Gratis</Popover>}
          >
            <i className="fa fa-wifi icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Piscina</Popover>}
          >
            <i className="fa fa-swimmer icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Estacionamiento Gratis</Popover>}
          >
            <i className="fa fa-car icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Seguridad 24hs</Popover>}
          >
            <i className="fa fa-user-shield icons"></i>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            overlay={<Popover>Parrilla</Popover>}
          >
            <i className="fa fa-fire icons"></i>
          </OverlayTrigger>
          {/* Disponibilidad */}
          <Row>
            <Col className="col-dias">
              <p className="m-0">Desde:</p>
              <p>
                <span className="date">Vie 6 Oct</span>
              </p>
            </Col>
            <Col className="col-dias border-left border-light">
              <p className="m-0">Hasta:</p>
              <p>
                <span className="date">Dom 8 Oct</span>
              </p>
            </Col>
          </Row>
          <Col className="incluye">
            Incluye traslado ida y vuelta a la Fiesta
          </Col>
        </Col>
        <Col md="4" sm="12" className="precio-alojamientos">
          <p>Precio final por 3 días y 2 noches</p>
          <h4>$48.500 / Persona</h4>
          <p className="small">(valor total: $388.000)</p>
          <Link className="small" to={"/formas-pago"}>
            <i className="fa fa-credit-card icons"></i> pagá hasta en 12 cuotas
          </Link>
          <Link
            className="button-alojamientos"
            to={{
              pathname: "/reserva",
              state: {
                alojamiento: "Complejo Don Arístides",
                tipoAlojamiento: "Cabañas para 8 personas"
              }
            }}
          >
            Reservar
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default Aristides;
